import { inject, Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DateTimeUtils } from "@sportyano/shared/utilis/date-time-utilits";

@Pipe({
	name: "timeFormat",
	standalone: true,
})
export class TimeFormatPipe implements PipeTransform {
	private _translateService = inject(TranslateService);
	transform(value: string): string {
		return DateTimeUtils.convertTo12HourFormat(
			value,
			this._translateService.currentLang as "en" | "ar"
		);
	}
}
